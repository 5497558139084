import React, { useState, useEffect } from 'react'
import { ProjectTemplate } from '../templates/Project/Project'
import { PageTemplate } from '../templates/Page/Page'
import {
    get,
    mapValues,
    has,
    assign,
    isObject,
    isString,
    isArray,
    cloneDeep,
    camelCase,
    upperCase,
    isNumber,
} from 'lodash-es'
import axios from 'axios'

import marked from 'marked'

axios.defaults.baseURL = '/.netlify/functions'

const Components = {
    project: ProjectTemplate,
    page: PageTemplate,
}

const pascalCase = (s) => {
    const [first, ...others] = camelCase(s).split('')
    return [upperCase(first), ...others].join('')
}

const contentfulUnwrap = (value, key) => {
    if (isArray(value)) {
        return value.map(contentfulUnwrap)
    } else if (isObject(value)) {
        if (has(value, 'sys')) {
            const { fields, sys } = value
            delete value['sys']
            delete value['fields']
            assign(value, fields)

            if (sys.type == 'Asset') {
                const assetType = get(fields, 'file.contentType', '').split('/')[0]

                if (assetType == 'image') {
                    const { width, height } = get(fields, 'file.details.image', {})

                    assign(value, {
                        fluid: {
                            aspectRatio:
                                isNumber(width) && isNumber(height) ? width / height : undefined,
                            src: get(fields, 'file.url'),
                        },
                    })
                }
            } else if (sys.type == 'Entry') {
                const entryType = get(sys, 'contentType.sys.id')

                if (entryType && entryType.includes('Section')) {
                    value.type = `ContentfulSection${pascalCase(entryType.replace('Section', ''))}`
                }
            }
        }
        mapValues(value, contentfulUnwrap)

        return value
    } else if (isString(value) && isString(key) && value.length > 255) {
        return { childMarkdownRemark: { html: marked(value) } }
    } else {
        return value
    }
}

const PreviewIndex = ({ globals, location }) => {
    const [entryType, setEntryType] = useState()
    const [previewProps, setPreviewProps] = useState({})
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        const parameters = new URLSearchParams(window.location.search)

        const loadPreviewData = () => {
            axios
                .post('/preview-data', {
                    entryId: parameters.get('entryId'),
                })
                .then(({ data }) => {
                    const entryType = get(data, 'data.sys.contentType.sys.id')
                    const rawEntry = cloneDeep(data.data)
                    const entry = contentfulUnwrap(data.data)
                    console.log('Got entry data', rawEntry, entry)

                    const commonData = {
                        globals: {
                            ...globals,
                        },
                        location,
                        siteTitle: 'Preview',
                    }

                    if (entryType == 'project') {
                        setPreviewProps({
                            ...commonData,
                            project: entry,
                        })
                    } else if (entryType == 'page') {
                        setPreviewProps({
                            ...commonData,
                            page: entry,
                        })
                    }
                    setInitialized(true)
                    setEntryType(entryType)
                })
        }

        loadPreviewData()
    }, [])

    const Component = get(Components, entryType)
    return Component ? (
        <Component {...previewProps} />
    ) : (
        <div>
            <span
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxHeight: '50vw',
                    fontSize: '3.6rem',
                }}
            >
                {initialized
                    ? `Content type '${entryType}' preview is not supported.`
                    : `Loading preview...`}
            </span>
        </div>
    )
}

export default PreviewIndex
